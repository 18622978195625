import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Is Graphic Design And What Does It Include?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Graphic design is a design process that combines graphics and text in such a way that communicates certain messages."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Can A Graphic Designer Do That I Can’t Do?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "A graphic designer not only offers creative skills but also experience. Though most graphic designers are already intuitively creative, they spend lots of time studying different design principles. Codestaff Graphic designers use design elements to transmit the required values and messages and evoke a certain feeling in the viewer."
        }
    }
}

const GraphicDesigner = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Graphic Designers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Graphic Designers. Top
                                    companies and start-ups choose Codestaff professional Graphic
                                    Designers for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Graphic Designer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE GRAPHIC DESIGNERS' banner='/graphic-designer.png' bannerAlt='graphic designer banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default GraphicDesigner