import React from 'react'
import Layout from '../components/layout'
import GraphicDesigner from '../components/categories/graphic-designer'
import Seo from '../components/seo'

const GraphicDesignerPage = ({ location }) => {
  return (
    <Layout location={location}>
      <GraphicDesigner />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Graphic Designer Freelancers | Codestaff'
  const description =
    'Hire the best Graphic Designer freelancers at Codestaff. Get the top 1% of Graphic Designer professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default GraphicDesignerPage
